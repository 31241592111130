/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import {
  Layout, Menu, Icon, Typography,
} from 'antd';
// import images
import logo from '../images/logo.png';
import dil from '../images/DIL-GROUP-LOGO.png';

const { SubMenu } = Menu;

const { Text } = Typography;

@inject('store')
@observer
class VerticalMenu extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.state = {
      error: null,
      activeItem: 'profile_account',
    };
  }

  menuCollapse = () => {
    this.store.updateValue('menu_collapsed', !this.store.menu_collapsed);
  }

  render() {
    const type = sessionStorage.getItem('type');
    return (

      <Layout className="fixed_sider" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Menu
          onClick={this.handleClick}
          style={{ width: this.store.menu_collapsed ? '80px' : '200px' }}
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1', 'sub2', 'sub4']}
          mode="inline"
          inlineCollapsed={this.store.menu_collapsed}
        >

          <div style={{
            height: '80px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <img alt="logo" src={logo} style={{ width: '60px' }} />
            <p className="mapato_header"> Mapato</p>
          </div>

          { type === 'employee'
        && (
        <SubMenu
          key="sub1"
          title={(
            <span>
              <Icon type="user" />
              <span>Your profile</span>
            </span>
)}
        >

          <Menu.Item key="1">
            <span>
              <Icon type="table" />
              <span>Account</span>
            </span>
            <Link to="/account" />
          </Menu.Item>
          <Menu.Item key="2">
            <span>
              <Icon type="dollar" />
              <span>Payments</span>
            </span>
            <Link to="/payments" />
          </Menu.Item>
          <Menu.Item key="3">
            <span>
              <Icon type="tool" />
              <span>Settings</span>
            </span>
            <Link to="/account" />
          </Menu.Item>
          <Menu.Item key="4">
            <span>
              <Icon type="inbox" />
              <span>Messages</span>
            </span>
            <Link to="/account" />
          </Menu.Item>

        </SubMenu>
        )
       }

          { type === 'business'

        && (
        <SubMenu
          key="sub2"
          title={(<span> <Icon type="appstore" /> <span>Organisation</span> </span>)}>
          <Menu.Item key="5">
            <span> <Icon type="table" /> <span>Account</span> </span>
            <Link to="/organization" />
          </Menu.Item>
          <Menu.Item key="6"> <span> <Icon type="team" /> <span>Employees</span></span>
            <Link to="/organization/employees" />
          </Menu.Item>
        </SubMenu>
        )}

          { type === 'business'

        && (
        <SubMenu
          key="sub4"
          title={(
            <span>
              <Icon type="dollar" />
              <span>Payments</span>
            </span>
        )}
        >
          <Menu.Item key="12">
            <span>
              <Icon type="dollar" />
              <span>Create Payment</span>
            </span>
            <Link to="/organization/payments" />
          </Menu.Item>

          <Menu.Item key="13">
            <span>
              <Icon type="dollar" />
              <span>All Payments</span>
            </span>
            <Link to="/organization/payments/all" />
          </Menu.Item>
        </SubMenu>
        )
        }

          {/* <SubMenu
          key="sub3"
          title={
            <span>
              <Icon type="question" />
              <span >Help</span>
            </span>
          }>

        <Menu.Item key="10">
          <span>
            <Icon type="mail" />
            <span>Messages</span>
          </span>
        <Link to={'/account'} />
        </Menu.Item>
        </SubMenu> */}

        </Menu>

        <div
          className="paddless_p"
          style={{
            width: '100%', display: 'flex', flexDirection: 'column', justifyContent: '', alignItems: 'center',
          }}
        >
          <img alt="logo" src={dil} style={{ width: '60px' }} />
          <Text>{this.store.menu_collapsed ? '' : 'Data Integrated Limited'}</Text>
          <p className="mapato_header" />
          <p className="mapato_header"> &copy; 2024 </p>
          <div
            style={{
              backgroundColor: '#1890ff', height: '40px', width: '100%', display: 'flex', alignContent: 'center',
            }}
            className="flex_center"
            onClick={this.menuCollapse}
          >
            <Icon style={{ color: 'white' }} type={this.store.menu_collapsed ? 'right' : 'left'} />
          </div>
        </div>
      </Layout>
    );
  }
}

export default VerticalMenu;
