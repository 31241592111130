import React, { Component } from 'react';

class Tag extends Component {
  render() {
    const property = `status_${this.props.status}`;
    return (
      <div style={{ width: 'fit-content' }}>
        <div className={property}>
          { this.props.status }
        </div>
      </div>
    );
  }
}

export default Tag;
