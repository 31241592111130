import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import moment from 'moment';
import { Button, Typography } from 'antd';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';

import Loader from '../../components/Loader';

const { Text } = Typography;

@inject('store') class EmployeePayments extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.state = {
      isLoading: false,
      error: null,

    };
  }

    getPayments = () => {
      this.setState({ error: null, isLoading: true });
      const config = { headers: { authorization: sessionStorage.getItem('token') } };

      axios.get(`${this.store.domain}/payments/${sessionStorage.getItem('organisation_id')}/${sessionStorage.getItem('eid')}`, config)
        .then((response) => {
          const { data } = response.data;
          data.forEach((element, index) => {
            element.pos = index + 1;
            element.created_at = moment(element.created_at).format('LLL');
            element.updated_at = moment(element.updated_at).format('LLLL');
          });
          this.store.updateValue('payments_list', data);
          this.setState({ error: null, isLoading: false });
        })
        .catch((error) => {
          this.setState({ error: error.response && error.response.data.error, isLoading: false });
        });
    };

    componentDidMount() {
      this.getPayments();
    }

    headerFormatter = (column, colIndex, { sortElement, filterElement }) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        { column.text }
        { filterElement }
        { sortElement }
      </div>
    )

    isEmployeeNormal = (employee_type) => employee_type === 'permanent' || employee_type === 'intern' || employee_type === 'non-permanent'

    tableNodata = (string) => (
      <div style={{
        paddingTop: '100px', paddingBottom: '100px', margin: 'auto', textAlign: 'center', backgroundColor: '#f8f8f8',
      }}
      >
        <Text>
          {' '}
          {`No ${string} found!`}
          {' '}
        </Text>
      </div>
    )

    acknowledgePayment = () => {

    }

    userAcknowledgePayment(cell, row) {
      return (
        <Button type="primary" onClick={() => this.acknowledgePayment(cell, row)}>Acknowledge</Button>
      );
    }

    PList = observer(() => {
      const options = {
        paginationSize: 4,
        pageStartIndex: 1,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        sizePerPageList: [

          { text: '20', value: 20 },
          { text: '50', value: 50 },
          { text: '100', value: 100 },
          { text: 'All', value: 1000 },
        ],
      };

      const columns = [
        { dataField: 'pos', text: 'No' },
        {
          dataField: 'names', text: 'Employee', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
        {
          dataField: 'created_at', text: 'Date created', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
        // { dataField: 'employee_type', text: 'Employee type', filter: textFilter(),headerFormatter: this.headerFormatter },
        // { dataField: 'updated_at', text: 'Last updated', filter: textFilter(),headerFormatter: this.headerFormatter },
        {
          dataField: 'period', text: 'Payment period', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
        {
          dataField: 'method', text: 'Payment method', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
        // { dataField: 'status', text: 'Status', filter: textFilter(),headerFormatter: this.headerFormatter },
        {
          dataField: 'amount', text: 'Amount', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
        {
          dataField: 'amount', text: 'Amount', filter: textFilter(), headerFormatter: this.headerFormatter,
        },

      ];

      if (!this.isEmployeeNormal(this.store.employee.employee_type)) {
        columns.push(
          {
            dataField: 'total_sales', text: 'Total sales', filter: textFilter(), headerFormatter: this.headerFormatter,
          },
          {
            dataField: 'commission_percent', text: 'Commission percentage', filter: textFilter(), headerFormatter: this.headerFormatter,
          },
          {
            dataField: 'commission', text: 'Commission', filter: textFilter(), headerFormatter: this.headerFormatter,
          },
        );
      }
      columns.push({ dataField: '', text: 'Action', formatter: this.userAcknowledgePayment.bind(this) });

      return (
        <div className="grid_margin card">
          <div>
            <BootstrapTable
              keyField="pos"
              bootstrap4
              noDataIndication={this.tableNodata(' Payments ')}
              data={this.store.payments_list}
              columns={columns}
              filter={filterFactory()}
              pagination={paginationFactory(options)}
                    // rowStyle={ rowStyle }
                    // selectRow={ selectRow }
              rowEvents={this.rowEvents}
            />
          </div>
        </div>
      );
    })

    PListHeader = () => (
      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px', marginLeft: '20px', marginTop: '20px',
      }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
          <p className="main_header">Your payments</p>
          <br />
        </div>
        <div />
      </div>

    )

    render() {
      return (
        <div>
          <this.PListHeader />
          { this.state.isLoading
            ? <Loader />
            : (
              <div className="overx">
                <Grid>
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <this.PList />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            )
          }
        </div>
      );
    }
}

export default EmployeePayments;
