import React from 'react';
import { Container } from 'semantic-ui-react';
import { Route, Redirect } from 'react-router-dom';
import HorizontalMenu from './HorizontalMenu';
import VerticalMenu from './VerticalMenu';

// import components

const Layout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      (sessionStorage.getItem('token') && (sessionStorage.getItem('eid') || sessionStorage.getItem('uid')))

        ? (
          <Container
            fluid
            style={{
              width: '100%', height: '100vh', display: 'flex', flexDirection: 'row', backgroundColor: '#E9ECF0',
            }}
          >
            <VerticalMenu className="fixed_sider" />
            <Layout style={{ width: '100%', height: '100vh' }}>
              <div style={{ width: '100%', height: '100vh', overflowY: 'scroll' }}>
                <HorizontalMenu {...props} />
                <Component {...props} />
              </div>
            </Layout>
          </Container>
        )
        : <Redirect to="/logout" />
    )}
  />
);

export default Layout;
