import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Grid } from 'semantic-ui-react';
import { inject } from 'mobx-react';
import moment from 'moment';
import axios from 'axios';
import {
  Button, Typography, Divider, message, Descriptions, Avatar, DatePicker, InputNumber,
} from 'antd';

import BootstrapTable from 'react-bootstrap-table-next';

import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { boldFormatter, italicFormatter, priceFormatter } from '../../../components/TableFormatter';

const { Text, Link } = Typography;
const { MonthPicker } = DatePicker;
const monthFormat = 'YYYY/MM';

@inject('store') class EmployeeDetails extends Component {
  constructor(props) {
    super(props);
    this.employee = this.props.employee;
    this.store = this.props.store;

    this.state = {
      isLoading: false,
      error: null,
      banks: [],
      pendingBalance: 0,
      payslipLoading: false,
      payslipMonth: null,
      payslipYear: null,
      pdfLink: '',
    };

    this.unknown = 'unknown';
    this.na = 'N/A';
  }

    onClickEdit = () => {
      this.props.history.push('edit');
    }

    onClickDelete = () => {
      this.updateEmployee({ is_active: false });
    }

    onPendingBalanceChange = (value) => {
      this.setState({ pendingBalance: value });
    }

    updateEmployee = (data) => {
      axios.put(`${this.store.domain}/employees/${sessionStorage.getItem('organisation_id')}/${this.employee.eid}`, { data }, { headers: { authorization: sessionStorage.getItem('token') } })
        .then(() => {
          message.success('Employee is no longer active');
        })
        .catch(() => {
          message.error('Something went wrong deleting the employee');
        });
    }

    getBanks = () => {
      this.setState({ error: null });
      const config = {
        headers: { authorization: sessionStorage.getItem('token') },
      };
      axios.get(`${this.store.domain}/users/${sessionStorage.getItem('organisation_id')}/${this.employee.eid}/bank_details`, config)
        .then((response) => {
          const { data } = response.data;
          this.setState({ banks: data });
        })
        .catch((error) => {
          this.setState({ error: error.response.data.error });
        });
    };

    onCalendarPanelChange = (value, mode) => {
      // console.log(moment(value).year(), moment(value).month());
      this.setState({ payslipMonth: moment(value).month(), payslipYear: moment(value).year() });
    }

    downloadPaySlip = () => {
      this.setState({ payslipLoading: true });
      if (this.state.payslipMonth === null || this.state.payslipYear === null) {
        message.error('Please Select a Date for the Payslip.');
        this.setState({ payslipLoading: false });
        return;
      }
      const config = {
        headers: { authorization: sessionStorage.getItem('token') },
      };
      const data = {
        moment_month: this.state.payslipMonth,
        moment_year: this.state.payslipYear,
        pendingBalance: this.state.pendingBalance,
      };
      axios.post(`${this.store.domain}/payments/${sessionStorage.getItem('organisation_id')}/${this.employee.eid}/payslip`, { data }, { headers: { authorization: sessionStorage.getItem('token') } })
        .then((response) => {
          const { data } = response;
          //   le.log(data);
          this.setState({ payslipLoading: false, pdfLink: data.url || null });
          message.success('Payslip Available for Download');
        })
        .catch((error) => {
          this.setState({ payslipLoading: false });
          message.error(error.data || 'Error');
        });
    }

    isEmployeeNormal = (employee_type) => employee_type === 'permanent' || employee_type === 'intern' || employee_type === 'non-permanent'

    DetailsText = (props) => (
      <div>
        <Text strong>{`${props.keys}  `}</Text>
        <Text>{props.value}</Text>
        <div style={{ height: '4px' }} />
      </div>
    )

    MyProfile = () => (
      <div
        className="grid_margin card"
        style={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start',
        }}
      >

        <div style={{ marginLeft: '5px', width: '100%' }}>
          <Text type="secondary" strong>EMPLOYEE PROFILE</Text>
          <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
          <p className="main_header">
            {' '}
            {this.employee.first_name}
            {' '}
            {this.employee.other_name}
            {' '}
            {this.employee.last_name}
          </p>

        </div>
      </div>
    )

    headerFormatter = (column, colIndex, { sortElement, filterElement }) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        { column.text }
        { filterElement }
        { sortElement }
      </div>
    )

    tableNodata = (string) => (
      <div style={{
        paddingTop: '100px', paddingBottom: '100px', margin: 'auto', textAlign: 'center', backgroundColor: '#f8f8f8',
      }}
      >
        <Text>
          {' '}
          {`${string} found!`}
          {' '}
        </Text>
      </div>
    )

    BankDetails = () => {
      const columns = [
        {
          dataField: 'bank_name', text: 'Bank name', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
        {
          dataField: 'ch_name', text: 'Cardholder names', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
        {
          dataField: 'account_no', text: 'Account number', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
        {
          dataField: 'code', text: 'Bank code', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
        {
          dataField: 'created_at', text: 'Date created', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
      ];

      return (
        <div>

          <div style={{ marginRight: '20px', marginLeft: '20px', marginTop: '20px' }}>
            <p className="main_header">Bank Details</p>
            <Text>Bank accounts of employee</Text>
          </div>

          <div className="grid_margin card">
            <BootstrapTable
              keyField="id"
              data={this.state.banks}
              columns={columns}
              noDataIndication={this.tableNodata('No bank accounts found')}
              filter={filterFactory()}
            />
          </div>
        </div>
      );
    }

    EmployeeDeductions = () => {
      let deductions = this.employee.amounts;
      deductions = deductions.filter((item) => item.type === 'deduction');
      if (this.employee.is_taxable) {
        const nssf = {};
        const nhif = {};

        nhif.id = 'nhif';
        nhif.name = 'NHIF Contribution';
        nhif.amount = this.employee.nhif;
        nhif.billing_period = 'Monthly';

        nssf.id = 'nssf';
        nssf.name = 'NSSF Contribution';
        nssf.amount = this.employee.nssf;
        nssf.billing_period = 'Monthly';

        deductions.push(nhif);
        deductions.push(nssf);
      }

      const columns = [
        { dataField: 'name', text: 'Name of deduction', ...boldFormatter },
        { dataField: 'amount', text: 'Amount', ...priceFormatter },
        {
          dataField: 'billing_period', text: 'Billing period', align: 'right', ...italicFormatter,
        },
      ];

      return (
        <div>

          <div style={{ marginRight: '20px', marginLeft: '20px', marginTop: '20px' }}>
            <p className="main_header">Deductions</p>
          </div>

          <div className="grid_margin card">
            <BootstrapTable
              keyField="id"
              data={deductions}
              columns={columns}
              noDataIndication={this.tableNodata('No deductions were found')}
              filter={filterFactory()}
            />
          </div>
        </div>
      );
    }

    EmployeeAllowances = () => {
      const { amounts } = this.employee;
      const allowances = amounts.filter((item) => item.type === 'allowance');

      const columns = [
        { dataField: 'name', text: 'Type of allowance', ...boldFormatter },
        { dataField: 'amount', text: 'Amount', ...priceFormatter },
        {
          dataField: 'billing_period', text: 'Billing period', align: 'right', ...italicFormatter,
        },
      ];

      return (
        <div>

          <div style={{ marginRight: '20px', marginLeft: '20px', marginTop: '20px' }}>
            <p className="main_header">Allowances</p>
          </div>

          <div className="grid_margin card">
            <BootstrapTable
              keyField="id"
              data={allowances}
              columns={columns}
              noDataIndication={this.tableNodata('No allowances were found')}
              filter={filterFactory()}
            />
          </div>
        </div>
      );
    }

    PersonalDetails = () => (
      <div className="grid_margin card">
        <Descriptions bordered title={this.employee.name || 'Unknown employee'} column={3}>
          <Descriptions.Item label="National Id number">{this.employee.national_id || this.unknown}</Descriptions.Item>
          <Descriptions.Item label="Position">{this.employee.job_desc || this.unknown}</Descriptions.Item>
          <Descriptions.Item label="Department">{this.employee.department || this.unknown}</Descriptions.Item>
          <Descriptions.Item label="Mobile number">{this.employee.phone || this.unknown}</Descriptions.Item>
          <Descriptions.Item label="Email">{this.employee.email || this.unknown}</Descriptions.Item>
          <Descriptions.Item label="Gender">{this.employee.gender || this.unknown}</Descriptions.Item>
          <Descriptions.Item label="KRA PIN number">{this.employee.kra_pin || this.unknown}</Descriptions.Item>
          <Descriptions.Item label="NSSF account number">{this.employee.nssf_no || this.unknown}</Descriptions.Item>
          <Descriptions.Item label="NHIF account number">{this.employee.nhif_no || this.unknown}</Descriptions.Item>
          <Descriptions.Item label="Residential status">{this.employee.residential_status || this.unknown}</Descriptions.Item>
          <Descriptions.Item label="Type of housing">{this.employee.housing_type || this.unknown}</Descriptions.Item>
          <Descriptions.Item label="Type">{this.employee.employee_type || this.unknown}</Descriptions.Item>
          <Descriptions.Item label="Address">{this.employee.address || this.unknown}</Descriptions.Item>
          <Descriptions.Item label="Country">{this.employee.country || this.unknown}</Descriptions.Item>
          <Descriptions.Item label="Date of birth">{this.employee.dob || this.unknown}</Descriptions.Item>
          <Descriptions.Item label="Status">{this.employee.is_active ? 'Active in this organisation' : 'Not active in this organisation'}</Descriptions.Item>
          <Descriptions.Item label="Employee number">{this.employee.employee_number || this.unknown}</Descriptions.Item>
          <Descriptions.Item label="Date created">{moment(this.employee.created_at).format('LLL') || this.unknown}</Descriptions.Item>
          <Descriptions.Item label="Last updated">{moment(this.employee.updated_at).format('LLL') || this.unknown}</Descriptions.Item>
          <Descriptions.Item label="Basic salary">{this.employee.basic_salary || 0}</Descriptions.Item>
          <Descriptions.Item label="Allowances total">{this.employee.allowances || 0}</Descriptions.Item>
          <Descriptions.Item label="Deductions total">{this.employee.deductions}</Descriptions.Item>
          <Descriptions.Item label="Net pay">{this.employee.net_pay || 0}</Descriptions.Item>
        </Descriptions>

      </div>
    )

    NameDetail = (props) => (
      <tr>
        <td>
          {' '}
          <Text strong>{props.keys}</Text>
        </td>
        <td className="table_data">{props.values}</td>
      </tr>
    )

    SalarySummary = () => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginRight: '20px', marginLeft: '20px', marginTop: '20px' }}>
          <p className="main_header">Income Summary</p>
          <Text>Summary of income</Text>
        </div>
        <div className="grid_margin card" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {/* {this.isEmployeeNormal(this.employee.employee_type)  */}
          <this.SimpleSummary keys="Salary/ Lumpsum" values={this.employee.basic_salary || 0} colors="#000" />
          { !this.isEmployeeNormal(this.employee.employee_type) && <this.SimpleSummary keys="Retainer Fees" values={this.employee.retainer_fee || 0} colors="#000" /> }
          <this.SimpleSummary keys="Allowances" values={this.employee.allowances || 0} colors="#4ECA8A" />
          <this.SimpleSummary keys="Deductions" values={this.employee.deductions || 0} colors="#DD3B62" />
          { this.employee.is_taxable && <this.SimpleSummary keys="PAYE" values={this.employee.paye || 0} colors="#DD3B62" /> }
          { !this.isEmployeeNormal(this.employee.employee_type) && <this.SimpleSummary keys="WithHolding Taxes" values={this.employee.wh_tax || 0} colors="#DD3B62" /> }
          <this.SimpleSummary keys="Net Pay" values={this.employee.net || 0} colors="#000" />
        </div>
      </div>
    )

    PaySlipAdvice = () => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginRight: '20px', marginLeft: '20px', marginTop: '20px' }}>
          <p className="main_header">Payslip</p>
          <Text> Select Year and Month of Payslip</Text>
          <br />
          <br />
          <MonthPicker format={monthFormat} onChange={this.onCalendarPanelChange} />
          <br />
          <br />
          <Text>Pending Balance for Selected Month</Text>
          <br />
          <br />
          <InputNumber style={{ width: '100%' }} defaultValue={0} formatter={(value) => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')} onChange={this.onPendingBalanceChange} />
          <br />
          <br />
          <Button loading={this.state.payslipLoading} type="primary" onClick={this.downloadPaySlip}> Generate payslip </Button>
          <a href={this.state.pdfLink} style={{ color: 'blue', marginLeft: '25px' }} target="_blank" rel="noopener noreferrer">
            {this.state.pdfLink}
          </a>
        </div>
      </div>
    )

    SimpleSummary = (props) => {
      const colorss = props.colors;
      return (
        <div className="" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
          <Text style={{
            fontSize: '1.2em', marginTop: '0px', marginBottom: '20px', fontFamily: '', fontWeight: '600',
          }}
          >
            {props.keys}
          </Text>
          <div>
            <Text style={{
              fontSize: '1.3em', marginTop: '0px', marginBottom: '20px', fontFamily: '', fontWeight: '700', color: colorss,
            }}
            >
KES
            </Text>
            <p style={{
              fontSize: '2.6em', marginTop: '0px', marginBottom: '17px', fontFamily: '', fontWeight: '700', color: colorss,
            }}
            >
              {props.values}
            </p>
          </div>
        </div>
      );
    }

    OrganisationDetails = () => (
      <div className="grid_margin card" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
        <Avatar
          size={256}
          style={{
            color: '#f56a00', backgroundColor: '#fde3cf', fontSize: '80px', margin: 'auto', widows: '100%',
          }}
        >
          {(this.props.employee.name.charAt(0)) || 'A'}
        </Avatar>
        <div />
      </div>
    )

    TitleComponent = () => (
      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px', marginLeft: '20px', marginTop: '20px',
      }}
      >
        <div>
          <p className="main_header">Employee Details</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button type="primary" icon="edit" style={{ marginRight: '20px' }} onClick={this.onClickEdit}>
                        Edit
          </Button>

          <Button type="danger" icon="delete" style={{ marginRight: '20px' }} onClick={this.onClickDelete}>
                        Delete
          </Button>
        </div>
      </div>
    )

    Headers = (props) => (
      <p style={{
        fontSize: '1.7em', margin: '0px', fontWeight: 'bold', marginLeft: '2%',
      }}
      >
        {props.header}
      </p>
    )

    componentDidMount() {
      if (this.props.employee === undefined) {
        this.props.history.push('/organization/employees');
        return null;
      }
      this.getBanks();
    }

    HomeComponent = () => (
      <div>
        <this.TitleComponent />
        <div className="overx">
          <Grid>
            <Grid.Row columns={1}>
              <Grid.Column stretched>
                <this.PersonalDetails />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <this.PaySlipAdvice />
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <this.EmployeeAllowances />
              </Grid.Column>
              <Grid.Column>
                <this.EmployeeDeductions />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    )

    render() {
      if (this.props.employee === undefined) {
        this.props.history.push('/organization/employees');
        return null;
      }
      return (
        <Switch>
          <Route
            render={(props) => (
              <this.HomeComponent
                {...props}
              />
            )}
          />
        </Switch>
      );
    }
}

export default EmployeeDetails;
