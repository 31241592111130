import React, { Component } from 'react';
import { DualRing } from 'react-css-spinners';

class Loader extends Component {
  render() {
    return (
      <div style={{ width: '100%', height: 'calc(100vh - 120px)', display: 'flex' }} className="flex_center">
        <DualRing color="#40A9FF" size={150} thickness={10} />
      </div>
    );
  }
}

export default Loader;
