import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import {
  Typography, Select, Button, message,
} from 'antd';
// import images
import utils from '../pages/organization/employees/utils';

const { Option } = Select;
const { Text } = Typography;

@inject('store')
class HorizontalMenu extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.state = {
      error: null,
      selected_organisation_id: null,
      selected_organisation_name: null,
    };
  }

    logOut = () => {
      message.info('bye!');
      this.props.store.clearAll();
      localStorage.clear();
      this.props.history.push('/logout');
    }

    registerOrganisation = () => {
      this.props.history.push('/organization/register');
    }

    setOrganizationValue = (key) => {
      const sel = this.store.organisations.filter((obj) => obj.key === key);

      const selected_org = sel[0];
      if (!selected_org) return message.warning('Selected organisation could not be read');
      sessionStorage.setItem('organisation_id', selected_org.oid);
      localStorage.setItem('organisation_id', selected_org.oid);
      localStorage.setItem('epesi_app_id', selected_org.epesi_app_id);
      this.setState({
        selected_organisation_id: selected_org.oid,
        selected_organisation_name: selected_org.name,
      });

      this.store.updateValue('organisation_address', selected_org.address || '');
      this.store.updateValue('organisation_country', selected_org.country || 'N/A');
      this.store.updateValue('organisation_email', selected_org.emails || 'N/A');
      this.store.updateValue('organisation_phone', selected_org.phones || 'N/A');
      this.store.updateValue('organisation_name', selected_org.name || 'N/A');
      this.store.updateValue('organisation_currency', selected_org.currency || 'N/A');

      this.props.store.clearEmployeesData();
      if (sessionStorage.getItem('eid') !== null && sessionStorage.getItem('type') === 'employee') {
        this.getEmployee();
        this.getEmployeeBanks();
      }
      console.log('iiiiiiiiiiiiiiiDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD');
      if (sessionStorage.getItem('uid') !== null) { // TODO Check if can get employees.
        console.log('DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD');
        this.store.getEmployees();
      }
    }

    getOrganizationOwned = () => {
      this.setState({ error: null });
      this.store.clearOrganizations();
      axios.get(`${this.store.domain}/organisations/owned`, { headers: { authorization: sessionStorage.getItem('token') } })
        .then((response) => {
          const tmp = [];
          response.data.data.forEach((org) => {
            const opt = org;
            opt.key = org.oid;
            opt.value = org.name;
            tmp.push(opt);
          });

          this.setState({ error: null });
          this.store.updateValue('organisations', tmp);
          if (localStorage.getItem('organisation_id')) {
            this.setOrganizationValue(localStorage.getItem('organisation_id'));
          } else {
            this.setOrganizationValue(tmp[0].key);
          }
        })
        .catch((error) => {
          this.setState({ error: error.response && error.response.data.error });
        });
    };

    getOrganizationEmployed = () => {
      this.setState({ error: null });
      const config = {
        headers: { authorization: sessionStorage.getItem('token') },
      };

      axios.get(`${this.store.domain}/organisations/employed/`, config)
        .then((response) => {
          const tmp = [];
          response.data.data.forEach((org) => {
            const opt = org;
            opt.key = org.oid;
            opt.value = org.name;
            tmp.push(opt);
          });

          this.setState({ error: null });
          this.store.updateValue('organisations', tmp);
          if (tmp[0]) {
            this.setOrganizationValue(tmp[0].key);
          } else {
            this.store.clearOrganizations();
          }
        })
        .catch((error) => {
          this.setState({ error: error.response && error.response.data.error });
        });
    };

    getEmployeeBanks = () => {
      this.setState({ error: null });
      const config = {
        headers: { authorization: sessionStorage.getItem('token') },
      };
      axios.get(`${this.store.domain}/users/${sessionStorage.getItem('organisation_id')}/${sessionStorage.getItem('eid')}/bank_details`, config)
        .then((response) => {
          const { data } = response.data;
          this.store.updateValue('banks', data);
        })
        .catch((error) => {
          this.setState({ error: error.response && error.response.data.error });
        });
    };

    getEmployee = () => {
      this.setState({ error: null });
      const config = {
        headers: { authorization: sessionStorage.getItem('token') },
      };
      axios.get(`${this.store.domain}/employees/${sessionStorage.getItem('organisation_id')}/${sessionStorage.getItem('eid')}`, config)
        .then((response) => {
          const { data } = response.data;
          const employee_data = utils.calculateEmployeeSummary(data.employees, response.data.data.amounts);
          this.store.updateValue('employee', employee_data[0]);
          this.store.updateValue('loggedInPerson', `${employee_data[0].first_name || ''}`);
          this.setState({ error: null });
        })
        .catch((error) => {
          this.setState({ error: error.response && error.response.data.error });
        });
    };

    TopBar = observer(() => {
      const opt = this.props.store.organisations.map((x) => <Option key={x.key}>{x.value}</Option>);

      return (
        <div>
          <div
            className=""
            style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#F7F9FA', paddingTop: '10px', paddingBottom: '10px',
            }}
          >
            <div style={{ marginLeft: '2%' }}>
              <Select
                defaultValue={localStorage.getItem('organisation_id')}
                style={{ width: '300px' }}
                placeholder="Select organisation"
                optionFilterProp="children"
                onChange={this.setOrganizationValue}
              >
                {opt}
              </Select>
            </div>

            <div style={{
              marginRight: '1%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center',
            }}
            >
              <div>
                <Text
                  className="normal_text"
                  style={{
                    fontSize: '1.2em', fontWeight: '400', color: '#757575', margin: '0px',
                  }}
                >
                  {`Hello, ${this.store.loggedInPerson}`}
                </Text>
              </div>
              <div style={{ width: '30px' }} />
              { (sessionStorage.getItem('uid') !== null && sessionStorage.getItem('type') === 'business')
            && (
            <Button type="primary" icon="plus" onClick={this.registerOrganisation}>
                Create Organisation
            </Button>
            )
            }
              <div style={{ width: '30px' }} />
              <Button type="danger" onClick={this.logOut}>
                Logout
              </Button>

            </div>
          </div>
        </div>
      );
    })

    componentDidMount() {
      this.store.clearAll();

      if (sessionStorage.getItem('eid') !== null && sessionStorage.getItem('type') === 'employee') {
        this.getOrganizationEmployed();
        this.store.updateValue('show_organisations_menu', false);
      }

      if (sessionStorage.getItem('uid') !== null && sessionStorage.getItem('type') === 'business') {
        this.getOrganizationOwned();
        this.store.updateValue('show_organisations_menu', true);
      }
    }

    render() {
      return (
        <div>
          <this.TopBar />
        </div>
      );
    }
}

export default HorizontalMenu;
