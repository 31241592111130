import moment from 'moment';

const convertToString = (value) => {
  if (!value) return '';
  return value.toLocaleString('en');
};

const getPAYE = (amount) => {
  let paye = 0;
  const min_limit = 12298;
  const next_limit = 11587;

  if (amount > min_limit) {
    const tax = (0.1 * min_limit);
    paye += tax;
    amount -= min_limit;
  }

  if (amount > 0) {
    const rem = amount > next_limit ? next_limit : amount;
    const tax = (0.15 * rem);
    paye += tax;
    amount -= next_limit;
  }

  if (amount > 0) {
    const rem = amount > next_limit ? next_limit : amount;
    const tax = (0.2 * rem);
    paye += tax;
    amount -= next_limit;
  }

  if (amount > 0) {
    const rem = amount > next_limit ? next_limit : amount;
    const tax = (0.25 * rem);
    paye += tax;
    amount -= next_limit;
  }

  if (amount > 0) {
    const tax = (0.3 * amount);
    paye += tax;
    amount -= next_limit;
  }

  return paye.toFixed(2) || 0;
};

// const getNSSF = (amount, use_new_rates = false) => {
//   if (!use_new_rates) {
//     return 200;
//   }

//   const tier1_max = 6000;
//   const tier2_max = 18000;
//   const next_tier = tier2_max - tier1_max;
//   let tier1_contribution = 0;
//   let tier2_contribution = 0;

//   if (amount > tier1_max) {
//     tier1_contribution = (0.06 * tier1_max);
//     amount -= tier1_max;
//   }

//   if (amount > 0) {
//     if (amount > next_tier) amount = next_tier;
//     tier2_contribution = (0.06 * amount);
//   }

//   return tier1_contribution + tier2_contribution;
// };

// const getNHIF = (amount) => {
//   if (amount < 6000) return 150;
//   if (amount >= 6000 && amount < 8000) return 300;
//   if (amount >= 8000 && amount < 12000) return 400;
//   if (amount >= 12000 && amount < 15000) return 500;
//   if (amount >= 15000 && amount < 20000) return 600;
//   if (amount >= 20000 && amount < 25000) return 750;
//   if (amount >= 25000 && amount < 30000) return 850;
//   if (amount >= 30000 && amount < 35000) return 900;
//   if (amount >= 35000 && amount < 40000) return 950;
//   if (amount >= 40000 && amount < 45000) return 1000;
//   if (amount >= 45000 && amount < 50000) return 1100;
//   if (amount >= 50000 && amount < 60000) return 1200;
//   if (amount >= 60000 && amount < 70000) return 1300;
//   if (amount >= 70000 && amount < 80000) return 1400;
//   if (amount >= 80000 && amount < 90000) return 1500;
//   if (amount >= 90000 && amount < 100000) return 1600;
//   if (amount >= 100000) return 1700;
// };

const calculateEmployeeSummary = (data, amounts, banks) => {
  let pos = 1;
  data.forEach((element) => {
    element.pos = pos;
    const date = moment(element.last_paid_date);
    if (date.isValid()) {
      element.last_paid_ago = moment(date).fromNow();
      element.last_paid_date = moment(date).format('lll');
    }
    pos++;
  });
  amounts = amounts.reduce((r, a) => {
    r[a.eid] = r[a.eid] || [];
    r[a.eid].push(a);
    return r;
  }, {});
  data.forEach((element) => {
    element.amounts = amounts[element.eid] || [];
  });
  data.forEach((element) => {
    const bank = banks.find((item) => item.eid === element.eid);
    if (bank) element.bank_name = bank.bank_name;
    if (bank) element.account_no = bank.account_no;
    if (bank) element.account_name = bank.account_name;
    if (bank) element.account_type = bank.account_type;
  });
  data.forEach((element) => {
    let total_allowances = 0;
    let total_pretax_ded = 0;
    let total_aftertax_ded = 0;

    const a = element.amounts.filter((item) => item.type === 'allowance');
    a.forEach((el) => { total_allowances += parseFloat(el.amount); });
    element.allowances = total_allowances || 0;

    const b = element.amounts.filter((item) => item.type === 'deduction' && item.deduct_before_tax === true);
    b.forEach((el) => { total_pretax_ded += parseFloat(el.amount); });
    element.deductions_pretax = total_pretax_ded || 0;

    const c = element.amounts.filter((item) => item.type === 'deduction' && item.deduct_before_tax === false);
    c.forEach((el) => { total_aftertax_ded += parseFloat(el.amount); });
    element.deductions_aftertax = total_aftertax_ded || 0;

    // if(!element.is_taxable ) {

    //   element.deductions = element.deductions_aftertax + element.deductions_pretax
    //   element.gross = element.basic_salary + element.allowances
    //   element.payment = 0

    //   element.net = element.gross
    //   element.net = convertToString(element.net)
    //   element.gross = convertToString(element.gross)
    //   element.personal_relief = convertToString(element.personal_relief)
    //   element.insurance_relief = convertToString(element.insurance_relief)
    //   element.basic_salary = convertToString(element.basic_salary || 0)

    // }else{

    if (element.employee_type === 'permanent' || element.employee_type === 'intern' || element.employee_type === 'non-permanent') {
      element.deductions = element.deductions_aftertax + element.deductions_pretax;
      element.gross = element.basic_salary; // + element.allowances
      element.payment = 0;

      // element.nssf = element.is_taxable && getNSSF(element.gross) || 0
      // element.nhif = element.is_taxable && getNHIF(element.gross) || 0
      element.taxable_pay = element.gross - element.deductions_pretax - element.nssf;
      element.paye_before_relief = element.is_taxable && getPAYE(element.taxable_pay) || 0;

      // element.paye = (element.paye_before_relief - element.personal_relief - element.insurance_relief - element.pension_fund_contribution - element.hosp_contibution - element.life_insurance_contibution - element.mortgage_contibution)
      if (element.paye < 0) element.paye = 0;
      // element.net = (element.taxable_pay - element.paye - element.nhif - element.deductions_aftertax)
      // element.deductions = element.deductions_aftertax + element.deductions_pretax + parseFloat(element.nhif) + parseFloat(element.nssf)
      element.deductions = parseFloat(element.nhif) + parseFloat(element.nssf);

      element.paye = convertToString(element.paye);
      element.amount = 0;
      element.net = convertToString(element.net_paye);
      element.gross = convertToString(element.gross);
      element.deductions = convertToString(element.deductions);
      element.allowances = convertToString(element.allowances);
      element.nssf = convertToString(element.nssf);
      element.nhif = convertToString(element.nhif);
      element.taxable_pay = convertToString(element.taxable_pay);
      element.personal_relief = convertToString(element.personal_relief);
      element.insurance_relief = convertToString(element.insurance_relief);
      element.basic_salary = convertToString(element.basic_salary || 0);
    } else if (element.employee_type === 'commission') {
      element.deductions = element.deductions_aftertax + element.deductions_pretax;
      // element.gross = element.retainer_fee + element.allowances
      element.payment = 0;

      // element.nssf = element.is_taxable && getNSSF(element.gross) || 0
      // element.nhif = element.is_taxable && getNHIF(element.gross) || 0
      element.taxable_pay = element.gross - element.deductions_pretax - element.nssf;
      element.paye_before_relief = element.is_taxable && getPAYE(element.taxable_pay) || 0;

      // element.paye = (element.paye_before_relief - element.personal_relief - element.insurance_relief - element.pension_fund_contribution - element.hosp_contibution - element.life_insurance_contibution - element.mortgage_contibution)
      if (element.paye < 0) element.paye = 0;
      element.net = (element.taxable_pay - element.paye - element.nhif - element.deductions_aftertax);
      element.deductions = (element.deductions_aftertax + element.deductions_pretax + element.nhif + element.nssf);
      element.amount = 0;
      element.paye = convertToString(element.paye);
      element.net = convertToString(element.net_pay);
      element.gross = convertToString(element.gross);
      element.deductions = convertToString(element.deductions);
      element.allowances = convertToString(element.allowances);
      element.nssf = convertToString(element.nssf);
      element.nhif = convertToString(element.nhif);
      element.taxable_pay = convertToString(element.taxable_pay);
      element.personal_relief = convertToString(element.personal_relief);
      element.insurance_relief = convertToString(element.insurance_relief);
      element.basic_salary = convertToString(element.basic_salary || 0);
    } else if (element.employee_type === 'contractor') {
      // if(element.)
      element.deductions = element.deductions_aftertax + element.deductions_pretax;
      // element.gross = element.basic_salary + element.allowances
      element.payment = 0;

      // element.nssf = 0 //getNSSF(element.gross)
      // element.nhif = 0 //getNHIF(element.gross)
      element.taxable_pay = element.gross - element.deductions_pretax - element.nssf;
      element.paye_before_relief = 0; // getPAYE(element.taxable_pay)

      element.paye = (element.paye_before_relief - element.personal_relief - element.insurance_relief - element.pension_fund_contribution - element.hosp_contibution - element.life_insurance_contibution - element.mortgage_contibution);
      if (element.paye < 0) element.paye = 0;

      element.taxable = element.gross - element.retainer_fee;

      if (element.wh_tax_percent > 0) {
        element.wh_tax = (element.wh_tax_percent / 100) * element.taxable;
      } else {
        element.wh_tax = 0;
      }

      // element.net = element.gross - element.wh_tax //+ element.retainer_fee

      // element.net = (element.taxable_pay - element.paye - element.nhif - element.deductions_aftertax)
      element.deductions = (element.deductions_aftertax + element.deductions_pretax + element.nhif + element.nssf);

      element.paye = convertToString(element.paye);
      element.amount = element.net;
      element.net = convertToString(element.net_pay);
      element.gross = convertToString(element.gross);
      element.deductions = convertToString(element.deductions);
      element.allowances = convertToString(element.allowances);
      element.nssf = convertToString(element.nssf);
      element.nhif = convertToString(element.nhif);
      element.taxable_pay = convertToString(element.taxable_pay);
      element.personal_relief = convertToString(element.personal_relief);
      element.insurance_relief = convertToString(element.insurance_relief);
      element.basic_salary = convertToString(element.basic_salary || 0);
    }

    // }
  });
  return data;
};

export default { calculateEmployeeSummary };
