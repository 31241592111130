import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Grid } from 'semantic-ui-react';
import { Typography, Divider } from 'antd';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

const { Text, Title } = Typography;

@inject('store')

class Account extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.state = {
      isLoading: false,
      error: null,
      // reload: true,
    };
    this.unknown = 'unknown';
    this.na = 'N/A';
  }

    DetailsText = (props) => (
      <div>
        <Text strong>{`${props.keys}  `}</Text>
        <Text>{props.value}</Text>
        <div style={{ height: '4px' }} />
      </div>
    )

    isEmployeeNormal = (employee_type) => employee_type === 'permanent' || employee_type === 'intern' || employee_type === 'non-permanent'

     headerFormatter = (column, colIndex, { sortElement, filterElement }) => (
       <div style={{ display: 'flex', flexDirection: 'column' }}>
         { column.text }
         { filterElement }
         { sortElement }
       </div>
     )

    BankDetails = observer(() => {
      const columns = [
        {
          dataField: 'bank_name', text: 'Bank Name', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
        {
          dataField: 'label', text: 'Label/Type', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
        {
          dataField: 'ch_name', text: 'CardHolder Names', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
        {
          dataField: 'account_no', text: 'Account Number', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
        {
          dataField: 'code', text: 'Bank Code', filter: textFilter(), headerFormatter: this.headerFormatter,
        },

      ];

      return (
        <div>
          <div style={{ marginRight: '20px', marginLeft: '20px', marginTop: '20px' }}>
            <p className="main_header">Bank Details</p>
            <Text>Bank accounts of employee</Text>
          </div>
          <div className="grid_margin card">
            <BootstrapTable
              keyField="id"
              data={[]} // todo
              columns={columns}
              noDataIndication={this.tableNodata('No bank accounts found')}
              filter={filterFactory()}
            />
          </div>
        </div>
      );
    })

    EmployeeDeductions = observer(() => {
      const columns = [
        {
          dataField: 'name', text: 'Type of Deductions', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
        {
          dataField: 'amount', text: 'Amount', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
        {
          dataField: 'billing_period', text: 'Billing period', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
      ];

      return (
        <div>

          <div style={{ marginRight: '20px', marginLeft: '20px', marginTop: '20px' }}>
            <p className="main_header">Deductions Details</p>
          </div>

          <div className="grid_margin card">
            <BootstrapTable
              keyField="id"
              data={[]}
              columns={columns}
              noDataIndication={this.tableNodata('No deductions were found')}
              filter={filterFactory()}
            />
          </div>
        </div>
      );
    })

    EmployeeAllowances = observer(() => {
      const columns = [
        {
          dataField: 'name', text: 'Type of allowance', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
        {
          dataField: 'amount', text: 'Amount', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
        {
          dataField: 'billing_period', text: 'Billing period', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
      ];

      return (
        <div>
          <div style={{ marginRight: '20px', marginLeft: '20px', marginTop: '20px' }}>
            <p className="main_header">Allowances Details</p>
          </div>

          <div className="grid_margin card">
            <BootstrapTable
              keyField="id"
              data={[]}
              columns={columns}
              noDataIndication={this.tableNodata('No allowances were found')}
              filter={filterFactory()}
            />
          </div>
        </div>
      );
    })

    PersonalDetails = observer(() => (
      <div className="grid_margin card">
        <table style={{ width: '100%', height: '100%' }}>
          <this.NameDetail keys="National Id Number " values={this.store.employee.national_id || this.unknown} />
          <this.NameDetail keys="Gender " values={this.store.employee.gender || this.unknown} />
          <this.NameDetail keys="KRA PIN Number " values={this.store.employee.kra_pin || this.unknown} />
          <this.NameDetail keys="NSSF Account Number " values={this.store.employee.nssf_no || this.unknown} />
          <this.NameDetail keys="NHIF Account Number " values={this.store.employee.nhif_no || this.unknown} />
          <this.NameDetail keys="Residential Status " values={this.store.employee.residential_status || this.unknown} />
          <this.NameDetail keys="Type of Housing " values={this.store.employee.housing_type || this.unknown} />
          <this.NameDetail keys="Date Joined " values={moment().format('LLL') || this.unknown} />
        </table>
      </div>
    ))

    NameDetail = (props) => (
      <tr>
        <td>
          {' '}
          <Text strong>{props.keys}</Text>
        </td>
        <td className="table_data">{props.values}</td>
      </tr>
    )

    SalarySummary = observer(() => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginRight: '20px', marginLeft: '20px', marginTop: '20px' }}>
          <p className="main_header">Income Summary</p>
          <Text>Summary of income</Text>
        </div>
        <div className="grid_margin card" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {/* {this.isEmployeeNormal(this.employee.employee_type)  */}
          <this.SimpleSummary keys="Salary/ Lumpsum" values={this.store.employee.basic_salary || 0} colors="#000" />
          { !this.isEmployeeNormal(this.store.employee.employee_type) && <this.SimpleSummary keys="Retainer Fees" values={this.store.employee.retainer_fee || 0} colors="#000" /> }
          <this.SimpleSummary keys="Allowances" values={this.store.employee.allowances || 0} colors="#4ECA8A" />
          <this.SimpleSummary keys="Deductions" values={this.store.employee.deductions || 0} colors="#DD3B62" />
          { this.store.employee.is_taxable && <this.SimpleSummary keys="PAYE" values={this.store.employee.paye || 0} colors="#DD3B62" /> }
          { !this.isEmployeeNormal(this.store.employee.employee_type) && <this.SimpleSummary keys="WithHolding Taxes" values={this.store.employee.wh_tax || 0} colors="#DD3B62" /> }
          <this.SimpleSummary keys="Net Pay" values={this.store.employee.net || 0} colors="#000" />
        </div>
      </div>
    ))

    SimpleSummary = (props) => {
      const colorss = props.colors;
      return (
        <div className="" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
          <Text style={{
            fontSize: '1.2em', marginTop: '0px', marginBottom: '20px', fontFamily: '', fontWeight: '600',
          }}
          >
            {props.keys}
          </Text>
          <div>
            <Text style={{
              fontSize: '1.3em', marginTop: '0px', marginBottom: '20px', fontFamily: '', fontWeight: '700', color: colorss,
            }}
            >
KES
            </Text>
            <p style={{
              fontSize: '2.6em', marginTop: '0px', marginBottom: '17px', fontFamily: '', fontWeight: '700', color: colorss,
            }}
            >
              {props.values}
            </p>
          </div>
        </div>
      );
    }

    tableNodata = (string) => (
      <div style={{
        paddingTop: '100px', paddingBottom: '100px', margin: 'auto', textAlign: 'center', backgroundColor: '#f8f8f8',
      }}
      >
        <Text>
          {' '}
          {`${string}`}
          {' '}
        </Text>
      </div>
    )

    OrganisationDetails = observer(() => (
      <div className="grid_margin card" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
        <div>
          <Text level={4} style={{ color: '#31A66A', fontWeight: 700, fontSize: '18px' }}>Organisation Selected</Text>
          <br />
          {' '}
          <br />
          <div style={{ marginLeft: '0px' }}>
            <Title level={4}>{this.store.organisation_name}</Title>
            <this.DetailsText keys="Address" value={this.store.organisation_address} />
            <this.DetailsText keys="" value={this.store.organisation_country} />
            <this.DetailsText keys="Email" value={this.store.organisation_email} />
            <this.DetailsText keys="Mobile" value={this.store.organisation_phone} />
            <this.DetailsText keys="Date Created" value={this.store.organisation_date_created} />
          </div>

        </div>
        <Divider />
        <div>
          <this.DetailsText keys="Status" value={this.store.employee.user_is_active ? 'You are active in this organisation' : 'You are not active in this organisation'} />
          <this.DetailsText keys="Employee Type" value={this.store.employee.employee_type} />
          <this.DetailsText keys="Employee Number" value={this.store.employee.employee_number} />
        </div>
      </div>
    ))

    Headers = (props) => (
      <p style={{
        fontSize: '2.0em', margin: '0px', fontFamily: 'Roboto', fontWeight: '700', marginLeft: '2%',
      }}
      >
        {props.header}
      </p>
    )

    componentDidMount() {

    }

    HomeComponent = () => (
      <div>
        <div className="overx">
          <Grid>
            <Grid.Row columns={3}>
              <Grid.Column stretched>
                <this.OrganisationDetails />
              </Grid.Column>
              <Grid.Column stretched>
                <this.MyProfile />
              </Grid.Column>
              <Grid.Column stretched>
                <this.PersonalDetails />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={1}>
              <Grid.Column>
                <this.SalarySummary />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={1}>
              <Grid.Column>
                <this.BankDetails />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
              <Grid.Column>
                <this.EmployeeAllowances />
              </Grid.Column>
              <Grid.Column>
                <this.EmployeeDeductions />
              </Grid.Column>
            </Grid.Row>

          </Grid>
        </div>
      </div>
    )

    render() {
      // todo: fix/remove layout
      return (
        <Switch>
          <Route
            path={`${this.props.match.path}/settings`}
            render={(props) => (
              <div>h</div>
            )}
          />

          {/* <Route
              path={`${this.props.match.path}/payments`}
              render={ props => (
                <EmployeePayments
                {...props}
                 data= {["hey"]}
                />
              )}
            />
           */}

          <Route
            render={(props) => (
              <this.HomeComponent
                {...props}
              />
            )}
          />
        </Switch>
      );
    }
}

export default Account;
