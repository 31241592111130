import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Grid } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { Button, Typography, Divider } from 'antd';
import axios from 'axios';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import { textFilter } from 'react-bootstrap-table2-filter';
import Loader from '../../components/Loader';

import Employees from './employees';
import Payments from './payments';
import PaymentsList from './payments/paymentsList';
import Register from './Register';
import Users from './users';
import { boldFormatter } from '../../components/TableFormatter';

const { Text } = Typography;

@inject('store') class Organization extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.state = {
      isLoading: false,
      error: null,
      name: '',
      alias: '',
      phone: [],
      email: [],
      created_at: '',
    };
  }

    addNewUser = () => {
      this.props.history.push('/organization/users');
    }

    getUsers = () => {
      this.setState({ error: null, isLoading: true });
      const config = { headers: { authorization: sessionStorage.getItem('token') } };

      axios.get(`${this.store.domain}/users/all`, config)
        .then((response) => {
          const { data } = response.data;
          data.forEach((element, index) => {
            element.pos = index + 1;
            element.created_at = moment(element.created_at).format('LLLL');
            element.names = `${element.first_name && element.first_name} ${element.last_name && element.last_name}`;
          });

          this.store.updateValue('organisation_users', data);
          const current_user_index = this.store.organisation_users.findIndex((element) => element.uid === sessionStorage.getItem('uid'));
          const current_user = this.store.organisation_users[current_user_index].first_name || '';
          this.store.updateValue('loggedInPerson', current_user);
          this.setState({ error: null, isLoading: false });
          this.store.postMonitoringData('organisation dashboard', 'account');
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    };

    deleteUser = (cell, row) => {
      const config = {
        headers: { authorization: sessionStorage.getItem('token') },
      };

      axios.delete(`${this.store.domain}/users/${row.uid}`, { data: {}, ...config })
        .then(() => {
          const users = this.store.organisation_users.filter((item) => item.uid !== row.uid);
          this.store.updateValue('organisation_users', users);
        })
        .catch((error) => {
          this.setState({ error: error.response.data.error });
        });
    }

    // OrganisationDetails = observer(() => (
    //   <div className="grid_margin card">
    //     <div>
    //       <p className="main_header">Organisation</p>
    //       <Divider />
    //       <div style={{ marginLeft: '0px' }}>
    //         <p className="main_header">{this.store.organisation_name}</p>
    //         <this.DetailsText keys="Address" value={this.store.organisation_address} />
    //         <this.DetailsText keys="Country" value={this.store.organisation_country} />
    //         <this.DetailsText keys="Email" value={this.store.organisation_email} />
    //         <this.DetailsText keys="Mobile" value={this.store.organisation_phone} />
    //         <this.DetailsText keys="Currency" value={this.store.organisation_currency} />
    //       </div>
    //     </div>
    //   </div>
    // ))

    // OrganisationMessages = observer(() => (
    //   <div className="grid_margin card" style={{ display: 'flex', flexDirection: 'row' }}>
    //     <div style={{ marginLeft: '5px', width: '100%' }}>
    //       <p className="main_header">Messages</p>
    //       <Divider />
    //       <div style={{ width: '100%', height: '100%', margin: 'auto' }}>
    //                   No Messages
    //       </div>

    //     </div>
    //   </div>
    // ))

    DetailsText = (props) => (
      <div>
        <Text strong>{`${props.keys}  `}</Text>
        <Text>{props.value}</Text>
        <div style={{ height: '4px' }} />
      </div>
    )

  headerFormatter = (column, colIndex, { sortElement, filterElement }) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      { column.text }
      <br />
      { filterElement }
      { sortElement }
    </div>
  )

  userDeleteFormatter(cell, row) {
    return (
      <Button type="danger" onClick={() => this.deleteUser(cell, row)}>Delete</Button>
    );
  }

    OrganisationUsers = observer(() => {
      const columns = [
        {
          dataField: 'names', text: 'Username', filter: textFilter(), headerFormatter: this.headerFormatter, ...boldFormatter,
        },
        {
          dataField: 'email', text: 'Email', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
        {
          dataField: 'created_at', text: 'Date added', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
        {
          dataField: 'permission', text: 'Permissions', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
        { dataField: '', text: 'Action', formatter: this.userDeleteFormatter.bind(this) },
      ];

      return (
        <div className="grid_margin card">

          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            <div>
              <p className="main_header">Payroll management</p>
              <p className="sub_title" style={{ fontSize: '1.0em', marginTop: '5px', marginBottom: '8px' }}>List of users involved in payroll management</p>
            </div>

            <Button type="primary" icon="plus" style={{ marginRight: '0px' }} onClick={this.addNewUser}>
                Add New User
            </Button>
          </div>

          <div>
            <BootstrapTable
              keyField="id"
              data={this.store.organisation_users}
              columns={columns}
              rowEvents={this.rowEvents}
            />
          </div>
        </div>
      );
    })

    OrganisationDefaultPage = () => (

      <div className="overx">
        { this.state.isLoading
          ? <Loader />
          : (
            <Grid>
              {/* <Grid.Row columns={2}>
                <Grid.Column stretched>
                  <this.OrganisationDetails />
                </Grid.Column>
                <Grid.Column stretched>
                  <this.OrganisationMessages />
                </Grid.Column>
              </Grid.Row> */}
              <Grid.Row>
                <Grid.Column stretched>
                  <this.OrganisationUsers />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )
                }
      </div>

    )

    componentDidMount() {
      this.getUsers();
    }

    render() {
      return (
        <Switch>
          <Route
            path={`${this.props.match.path}/employees`}
            render={(props) => (
              <Employees
                {...props}
                data={['hey']}
              />
            )}
          />

          <Route
            path={`${this.props.match.path}/payments`}
            exact
            render={(props) => (
              <Payments
                {...props}
              />
            )}
          />

          <Route
            path={`${this.props.match.path}/payments/all`}
            exact
            render={(props) => (
              <PaymentsList
                {...props}
              />
            )}
          />

          <Route
            path={`${this.props.match.path}/register`}
            render={(props) => (
              <Register
                {...props}
                data={['hey']}
              />
            )}
          />

          <Route
            path={`${this.props.match.path}/users`}
            render={(props) => (
              <Users
                {...props}
                data={['hey']}
                getUsers={this.getUsers}
              />
            )}
          />

          <Route
            render={(props) => (
              <this.OrganisationDefaultPage
                {...props}
              />
            )}
          />
        </Switch>
      );
    }
}

export default Organization;
