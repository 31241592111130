import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { inject } from 'mobx-react';
import {
  Form, Input, Select, Alert, Button,
} from 'antd';
import axios from 'axios';

const { Option } = Select;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

@inject('store') class AddUser extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.state = {
      isLoading: false,
      error: null,
      success: null,
    };
  }

    handleAddUser = (e) => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) this.addUser(values);
      });
    };

    addUser = (values) => {
      const data = values;
      this.setState({ isLoading: true });
      axios.post(`${this.store.domain}/users/add`, { data }, { headers: { authorization: sessionStorage.getItem('token') } })
        .then(() => {
          this.setState({ success: true, isLoading: false, error: null });
          this.props.getUsers();
        })
        .catch((error) => {
          this.setState({ error: error.response.data.error, isLoading: false, success: false });
        });
    }

    AddUserComponent = () => {
      const {
        getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
      } = this.props.form;

      const firstNameError = isFieldTouched('first_name') && getFieldError('first_name');
      const lastNameError = isFieldTouched('last_name') && getFieldError('last_name');
      const phoneError = isFieldTouched('phone') && getFieldError('phone');
      const idError = isFieldTouched('national_id') && getFieldError('national_id');

      const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 10 },
      };

      return (
        <div className="grid_margin card">

          <Form {...formItemLayout} onSubmit={this.handleAddUser}>
            <Form.Item label="First name" validateStatus={firstNameError ? 'error' : ''} help={firstNameError || ''}>
              {getFieldDecorator('first_name', { rules: [{ required: true, message: 'Please input first name' }] })(
                <Input placeholder="First Name" />,
              )}
            </Form.Item>

            <Form.Item label="Last name" validateStatus={lastNameError ? 'error' : ''} help={lastNameError || ''}>
              {getFieldDecorator('last_name', { rules: [{ required: true, message: 'Please input last name' }] })(
                <Input placeholder="Last Name" />,
              )}
            </Form.Item>

            <Form.Item label="Phone number" validateStatus={phoneError ? 'error' : ''} help={phoneError || ''}>
              {getFieldDecorator('phone', { rules: [{ required: true, whitespace: true, message: 'Please input phone number' }] })(
                <Input placeholder="Eg 0712345678" />,
              )}
            </Form.Item>

            <Form.Item label="National ID" validateStatus={idError ? 'error' : ''} help={idError || ''}>
              {getFieldDecorator('national_id', { rules: [{ required: true, message: 'Please input employee national id ' }] })(
                <Input placeholder="Eg 1234567" />,
              )}
            </Form.Item>

            <Form.Item label="Email address">
              {getFieldDecorator('email', { rules: [{ required: true }] })(
                <Input placeholder="Eg abc@cdef.com" />,
              )}
            </Form.Item>

            <Form.Item style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())} loading={this.state.isLoading}>
                    Create new user
              </Button>
            </Form.Item>

          </Form>
        </div>
      );
    }

    SuccessErrorComponent = () => {
      const { success, error } = this.state;
      return (
        <div style={{
          marginLeft: '20px', marginRight: '20px', marginTop: '20px', marginBottom: '5px',
        }}
        >
          {error
            && (
            <Alert
              message="Error"
              description={error}
              type="error"
              closable
              showIcon
            />
            )}
          {success
              && (
              <Alert
                message="Success"
                description="New user created!"
                type="success"
                closable
                showIcon
              />
              )}
        </div>
      );
    }

      TitleComponent = () => (
        <div style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px', marginLeft: '30px', marginTop: '20px',
        }}
        >
          <p
            className="normal_text"
            style={{
              fontSize: '1.5em', fontWeight: '700', margin: '0px', color: '#000',
            }}
          >
Add new user
          </p>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {/* <Button type="danger" style={{ marginRight:'20px'}}>
                        Deactivate organisation
                    </Button> */}
          </div>
        </div>
      )

      componentDidMount() {
        this.props.form.validateFields();
      }

      render() {
        return (
          <div>
            <this.TitleComponent />
            <div className="overx">
              <this.SuccessErrorComponent />
              <Grid>
                <Grid.Row columns={1}>
                  <Grid.Column stretched>
                    <this.AddUserComponent />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        );
      }
}

const AddUserWrapper = Form.create({ name: 'org_add_user' })(AddUser);

export default AddUserWrapper;
