import React, { Component } from 'react';

class PriceFormatter extends Component {
  render() {
    return (
      <div style={{ display: 'flex', float: 'right', flexDirection: 'row' }}>
        <span>
          <strong>
            { this.props.amount && parseFloat(this.props.amount).toLocaleString('en') || '0.00' }
          </strong>
        </span>
               &ensp;
        <span style={{ color: 'grey' }}>
          { localStorage.getItem('currency') || 'KES' }
        </span>
      </div>
    );
  }
}

export default PriceFormatter;
