import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// import pages
import Login from './pages/security/Login';
import Signup from './pages/security/Signup';
import Organization from './pages/organization';
import Account from './pages/account';
import EmployeePayments from './pages/account/employeePayments';
import SetPassword from './pages/security/SetPassword';
import Layout from './components/Layout';

// logout user
const Logout = () => {
  sessionStorage.clear();
  return <Redirect to="/login" />;
};

class App extends Component {
  render() {
    return (
    // <div style={{backgroundImage: 'url(' + Image + ')', height: '100%', width: '100%'}}>
      <Switch>
        {/** security pages */}
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/set" component={SetPassword} />
        {/* <Route exact path='/change-password' component={ChangePassword} /> */}

        {/** organization pages */}
        {/* <Layout exact path={'/organization/register'} component={RegisterOrganization} /> */}
        <Layout path="/organization" component={Organization} />
        {/* <Layout exact path={'/organization/payments/all'} component={PaymentsList} /> */}
        {/* <Layout exact path={'/organization/employees'} component={AllEmployees} />
                <Layout exact path={'/organization/employees/:employee_id'} component={EmployeeDetails} />
                <Layout exact path={'/organization/explore'} component={Explore} />
                <Layout exact path={'/organization/explore/user/:user_id'} component={ExploreUser} />

                <Layout exact path={'/organization/payments/employee/:employee_id'} component={AllEmployeePayments} />
                <Layout exact path={'/organization/payments/:payment_id'} component={Payment} />
                <Layout exact path={'/organization/settings'} component={OrganizationSettings} /> */}

        {/** user account pages */}
        <Layout exact path="/account" component={Account} />
        <Layout exact path="/payments" component={EmployeePayments} />
        {/* <Layout exact path={'/account/settings'} component={AccountSettings} /> */}
        <Redirect path="/" to="/account" />
      </Switch>

    );
  }
}

export default App;
