/* eslint-disable no-mixed-operators */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import { inject } from 'mobx-react';
import axios from 'axios';
import {
  Form, message, Input, Button, Typography, Radio, Alert,
} from 'antd';
import logo from '../../images/logo.png';
import mapato_banner from '../../images/MobiTill-on-White-bg.png';

const { Text } = Typography;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

@inject('store') class SetPassword extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.state = {
      success: null,
      error: null,
      isLoading: false,
      confirmDirty: false,
      pinSent: false,
      email: null,
      type: null,
    };
  }

    handleDetailsSubmit = (e) => {
      e.preventDefault();
      console.log(this.props.form);
      this.props.form.validateFields(['email', 'phone', 'log_type'], (err, values) => {
        if (!err) {
          const type = values.log_type;
          const { email } = values;
          this.setState({ type, email });
          this.sendUserDetails(values);
        }
      });
    };

    handlePassSubmit = (e) => {
      e.preventDefault();
      this.props.form.validateFields(['user_pin', 'password', 'confirm_password'], (err, values) => {
        if (!err) {
          this.sendUserPass(values);
        }
      });
    };

    sendUserDetails = (values) => {
      const data = values;
      data.type = data.log_type;
      // data.phone = data.phones;

      delete data.log_type;

      axios.post(`${this.store.domain}/auth/requestpin`, { data })
        .then(() => {
          message.info('SMS sent successfully!');
          this.setState({
            isLoading: false,
            error: null,
            pinSent: true,
            success: null,
          });
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            pinSent: false,
            error: error.response && error.response.data.error || 'Unknown error, try again',
          });
          message.error(error.response && error.response.data.error || 'Unknown error, try again');
        });
    }

    sendUserPass = (values) => {
      const data = values;
      data.type = this.state.type;
      data.email = this.state.email;

      delete data.confirm_password;

      axios.post(`${this.store.domain}/auth/requestpass`, { data })
        .then(() => {
          this.setState({
            isLoading: false,
            error: null,
            success: 'Successfully set password. \n You can now log in.',
          });
          message.success('Successfully set password. \n You can now log in.');
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            error: error.response && error.response.data.error || 'Unknown error, try again',
          });
          message.error(error.response && error.response.data.error || 'Unknown error, try again');
        });
    }

    handleConfirmBlur = (e) => {
      const { value } = e.target;
      this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    compareToFirstPassword = (rule, value, callback) => {
      const { form } = this.props;
      if (value && value !== form.getFieldValue('password')) {
        callback('Two passwords that you entered are inconsistent!');
      } else {
        callback();
      }
    };

    validateToNextPassword = (rule, value, callback) => {
      const { form } = this.props;
      if (value && this.state.confirmDirty) {
        form.validateFields(['confirm_password'], { force: true });
      }
      callback();
    };

    componentDidMount() {
      this.props.form.validateFields();
      this.setState({ pinSent: this.store.pinReceived });
      // this.props.form.resetFields()
    }

    render() {
      const {
        error,
        success,
      } = this.state;

      const layout1 = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      };

      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      };

      const {
        getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
      } = this.props.form;

      const phoneError = isFieldTouched('phones') && getFieldError('phones');
      const email = isFieldTouched('email') && getFieldError('email');
      const password = isFieldTouched('password') && getFieldError('password');
      const confirm_password = isFieldTouched('confirm_password') && getFieldError('confirm_password');
      const user_pin = isFieldTouched('user_pin') && getFieldError('user_pin');

      return (
        <div style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '100%', width: '100%',
        }}
        >

          <div
            className="flex_center"
            style={{
              display: 'flex', width: '60%', height: '100%', margin: 'auto',
            }}
          >
            <img src={mapato_banner} alt="logos" style={{ width: '60%', margin: 'auto' }} />
          </div>

          <div style={{
            width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
          }}
          >

            { !this.state.pinSent
            && (
            <Form onSubmit={this.handleDetailsSubmit} className="login-form" style={{ margin: 'auto' }}>
              <img src={logo} alt={logo} style={{ height: '4%' }} />
              {' '}
              <br />
              <Text strong style={{ fontSize: '25px', color: '#000000', fontWeight: 'bold' }}>Request for PIN code</Text>
              <br />

              <Text>Enter below details to request for a pin</Text>
              <p />

              <Form.Item label="Email" {...layout1} validateStatus={email ? 'error' : ''} help={email || ''}>
                {getFieldDecorator('email', { rules: [{ required: true, message: 'Please input employee email' }] })(
                  <Input placeholder="someone@email.com" />,
                )}
              </Form.Item>

              <Form.Item {...layout1} label="Phone Number" validateStatus={phoneError ? 'error' : ''} help={phoneError || ''}>
                {getFieldDecorator('phone', { rules: [{ required: true, whitespace: true, message: 'Please input phone number' }] })(
                  <Input placeholder="0712345678" />,
                )}
              </Form.Item>

              <Form.Item {...layout1} label="Type of request">
                {getFieldDecorator('log_type', {
                  initialValue: 'business',
                  rules: [{ required: true, message: 'Please select if you are a business or employee!' }],
                })(
                  <Radio.Group name="log_type">
                    <Radio value="business">Business</Radio>
                    <Radio value="employee">Employee</Radio>
                  </Radio.Group>,
                )}

              </Form.Item>

              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError(['national_id', 'phones', 'log_type']))}>
                Request for PIN
                </Button>
              </Form.Item>

              {error
            && (
            <Alert
              message="Error"
              description={error}
              type="error"
              closable
            />
            )}
            </Form>
            )
            }

            { this.state.pinSent
            && (
            <Form onSubmit={this.handlePassSubmit} className="login-form" style={{ margin: 'auto' }}>
              <img src={logo} alt={logo} style={{ height: '4%' }} />
              {' '}
              <br />
              <Text strong style={{ fontSize: '25px', color: '#000000', fontWeight: 'bold' }}>Set Password</Text>
              <br />
              <Text strong>A pin has been sent to your phone number</Text>
              <br />
              <Text secondary>Enter the pin received and your new password</Text>
              <p />
              <Form.Item {...layout1} label="Enter PIN" validateStatus={user_pin ? 'error' : ''}>
                {getFieldDecorator('user_pin', { rules: [{ required: true, message: 'Enter the pin sent to your phone' }] })(
                  <Input placeholder="0000" />,
                )}
              </Form.Item>

              <Form.Item {...layout1} label="Password" hasFeedback validateStatus={password ? 'error' : ''} help={password || ''}>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    {
                      validator: this.validateToNextPassword,
                    },
                  ],
                })(<Input.Password />)}
              </Form.Item>

              <Form.Item {...layout1} label="Confirm Password" hasFeedback validateStatus={confirm_password ? 'error' : ''} help={confirm_password || ''}>
                {getFieldDecorator('confirm_password', {
                  rules: [
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    {
                      validator: this.compareToFirstPassword,
                    },
                  ],
                })(<Input.Password onBlur={this.handleConfirmBlur} />)}
              </Form.Item>

              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError(['user_pin', 'password', 'confirm_password']))}>
                Set New Password
                </Button>
              </Form.Item>

              {error
            && (
            <Alert
              message="Error"
              description={error}
              type="error"
              closable
            />
            )}

              {success
            && (
            <Alert
              message="Success"
              description={success}
              type="success"
              closable
            />
            )}

            </Form>
            )
          }

          </div>
        </div>
      );
    }
}
const WrappedSetPassForm = Form.create({ name: 'space_set_passwd' })(SetPassword);

export default WrappedSetPassForm;
