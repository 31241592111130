import React, { Component } from 'react';
// eslint-disable-next-line no-unused-vars
import { Grid } from 'semantic-ui-react';
import { inject } from 'mobx-react';
import {
  Form, Input, Modal, message, Alert, Button, Icon, Result,
} from 'antd';
import axios from 'axios';

const { Search } = Input;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

@inject('store') class Register extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.state = {
      isLoading: false,
      error: null,
      success: null,
      isModalVisible: false,
      saccoNameFromSearch: null,
      saccoId: null,
    };
  }

    handleRegisterOrg = (e) => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) this.registerOrg(values);
      });
    };

    setOrganizationValue = (key) => {
      // eslint-disable-next-line radix
      key = parseInt(key);
      const sel = this.store.organisations.filter((obj) => obj.key === key);
      const selected_org = sel[0];
      if (!selected_org) return message.warning('Selected organisation could not be read');

      sessionStorage.setItem('organisation_id', selected_org.oid);
      this.store.updateValue('organisation_address', selected_org.address || '');
      this.store.updateValue('organisation_alias', selected_org.alias || 'N/A');
      this.store.updateValue('organisation_country', selected_org.country || 'N/A');
      this.store.updateValue('organisation_date_created', selected_org.created_at || 'N/A');
      this.store.updateValue('organisation_email', selected_org.email || 'N/A');
      this.store.updateValue('organisation_phone', selected_org.phone || 'N/A');
      this.store.updateValue('organisation_name', selected_org.name || 'N/A');

      if (sessionStorage.getItem('eid') !== null && sessionStorage.getItem('type') === 'employee') {
        this.getEmployee();
        this.getEmployeeBanks();
      }
      // console.log('iiiiiiiiiiiiiiiDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD');
      // if (sessionStorage.getItem('uid') !== null) { // TODO Check if can get employees.
      //   console.log('DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD');
      //   this.store.getEmployees();
      // }
    }

    getOrganizationOwned = () => {
      this.setState({ error: null });
      axios.get(`${this.store.domain}/organisations/owned`, { headers: { authorization: sessionStorage.getItem('token') } })
        .then((response) => {
          const tmp = [];
          response.data.data.map((org) => {
            const opt = org;
            opt.key = org._id;
            opt.value = org.name;
            return tmp.push(opt);
          });

          this.setState({ error: null });
          this.store.updateValue('organisations', tmp);
          if (tmp[0]) {
            this.setOrganizationValue(tmp[0].key);
          } else {
            this.store.clearOrganizations();
          }
        })
        .catch((error) => {
          this.setState({ error: error.response && error.response.data.error });
        });
    };

    registerOrg = (values) => {
      const data = values;
      axios.post(`${this.store.domain}/organisations/register`, { data }, { headers: { authorization: sessionStorage.getItem('token') } })
        .then(() => {
          this.setState({ success: true, error: null });
          this.getOrganizationOwned();
        })
        .catch((error) => {
          this.setState({ error: error.response.data.error, success: false });
        });
    }

    RegisterComponent = () => {
      const {
        getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
      } = this.props.form;

      const nameError = isFieldTouched('name') && getFieldError('name');

      const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
      };

      return (
        <div className="grid_margin card">

          <Form {...formItemLayout} onSubmit={this.handleRegisterOrg}>

            <Form.Item label="Company Name" validateStatus={nameError ? 'error' : ''} help={nameError || ''}>
              {getFieldDecorator('name', { rules: [{ required: true, message: 'Please input company name' }] })(
                <Input placeholder="eg ABDCEF Ltd" />,
              )}
            </Form.Item>

            <Form.Item label="Phone Number">
              {getFieldDecorator('phones', { rules: [{ required: false }] })(<Input placeholder="Eg 50000" />)}
            </Form.Item>

            <Form.Item label="Email">
              {getFieldDecorator('emails', { rules: [{ required: false }] })(<Input placeholder="Eg 50000" />)}
            </Form.Item>

            <Form.Item label="Physical address">
              {getFieldDecorator('address', { rules: [{ required: false }] })(<Input placeholder="Eg 50000" />)}
            </Form.Item>

            <Form.Item label="Country">
              {getFieldDecorator('country', { rules: [{ required: false }] })(<Input placeholder="Eg 50000" />)}
            </Form.Item>

            <Form.Item label="Bussiness PIN">
              {getFieldDecorator('bussiness_pin', { rules: [{ required: false }] })(<Input placeholder="Eg 50000" />)}
            </Form.Item>

            <Form.Item label="currency">
              {getFieldDecorator('currency', { rules: [{ required: false, max: 3 }] })(<Input placeholder="Eg 50000" />)}
            </Form.Item>

            <Form.Item style={{ textAlign: 'right' }}>

              <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
              Create Organisation
              </Button>
            </Form.Item>

          </Form>
        </div>
      );
    }

    SuccessErrorComponent = () => {
      const { success, error } = this.state;
      return (
        <div style={{
          marginLeft: '20px', marginRight: '20px', marginTop: '20px', marginBottom: '5px',
        }}
        >
          {error
            && (
            <Alert
              message="Error"
              description={error}
              type="error"
              closable
              showIcon
            />
            )}
          {success
              && (
              <Alert
                message="Success"
                description="New organisation created!"
                type="success"
                closable
                showIcon
              />
              )}
        </div>
      );
    }

    showModal = () => {
      this.setState({ isModalVisible: true });
    }

    handleOk = () => { this.setState({ isModalVisible: false }); }

    handleCancel = () => { this.setState({ isModalVisible: false }); }

    TitleComponent = () => (
      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px', marginLeft: '30px', marginTop: '20px',
      }}
      >
        <p
          className="normal_text"
          style={{
            fontSize: '1.5em', fontWeight: '700', margin: '0px', color: '#000',
          }}
        >
Create Organisation
        </p>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button type="link" style={{ marginRight: '20px' }} onClick={() => this.showModal()}>
                        Import organisation from Epesi.
          </Button>
        </div>
        {/* <div  style={{ display:'flex', flexDirection:'row'}}>
                    <Button type="danger" style={{ marginRight:'20px'}}>
                        Deactivate organisation
                    </Button>
                </div> */}
      </div>
    )

    componentDidMount() {
      this.props.form.validateFields();
    }

    searchOrganisationWithId = (e) => {
      const data = { id: e };
      axios.post(`${this.store.domain}/organisations/epesi/search`, { data }, { headers: { authorization: sessionStorage.getItem('token') } })
        .then((response) => {
          this.setState({ saccoNameFromSearch: response.data.name, saccoId: e });
        })
        .catch((error) => {
          this.setState({ error: error.response.data.error, success: false, saccoNameFromSearch: null });
        });
    }

    importOrganisationFromEpesi = () => {
      const data = { epesi_app_id: this.state.saccoId, pay_salary_from_savings: true, name: this.state.saccoNameFromSearch };
      this.registerOrg(data);
    }

    ModalAddOrganisationFromEpesi = () => (
      <div>
        <Modal
          title="Add organisation from Epesi"
          visible={this.state.isModalVisible}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
                      Cancel
            </Button>,
          ]}
        >
          <Search placeholder="Enter Epesi app id" enterButton="Search" onSearch={(e) => this.searchOrganisationWithId(e)} />
          {this.state.saccoNameFromSearch
                && (
                <Result
                  icon={<Icon type="success" theme="twoTone" />}
                  title={`Sacco name: ${this.state.saccoNameFromSearch}`}
                  extra={<Button type="primary" onClick={this.importOrganisationFromEpesi}>Import sacco details</Button>}
                />
                )
                }
        </Modal>
      </div>
    )

    render() {
      return (
        <div>
          <this.TitleComponent />
          <div className="overx">
            <this.SuccessErrorComponent />
            <this.ModalAddOrganisationFromEpesi />
            <Grid>
              <Grid.Row columns={1}>
                <Grid.Column stretched>
                  <this.RegisterComponent />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>
      );
    }
}

const RegisterOrgWrapper = Form.create({ name: 'organisation_registration' })(Register);

export default RegisterOrgWrapper;
