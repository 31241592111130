import React from 'react';

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const positionFormat = (cell) => (
  <span style={{ color: 'grey' }}>
    {cell}
  </span>
);

const boldFormat = (cell) => (
  <span>
    <strong>{cell}</strong>
  </span>
);

const italicFormat = (cell) => {
  if (cell == null) return null;
  return (
    <span>
      <i>{capitalizeFirstLetter(cell)}</i>
    </span>
  );
};

const booleanFormat = (cell) => (
  <span>
    <i>{cell === true ? 'True' : 'False'}</i>
  </span>
);

const priceFormat = (cell) => (
  <div style={{ display: 'flex', float: 'right', flexDirection: 'row' }}>
    <span>
      <strong>
        { cell && parseFloat(cell).toLocaleString('en') || '0.00' }
      </strong>
    </span>
    &ensp;
    <span style={{ color: 'grey' }}>
      { localStorage.getItem('currency') || 'KES' }
    </span>
  </div>
);

const positionFormatter = { formatter: positionFormat };
const boldFormatter = { formatter: boldFormat };
const italicFormatter = { formatter: italicFormat };
const priceFormatter = { formatter: priceFormat };
const booleanFormatter = { formatter: booleanFormat };

export {
  positionFormatter,
  boldFormatter,
  italicFormatter,
  priceFormatter,
  booleanFormatter,
};
