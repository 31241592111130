/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import moment from 'moment';
import io from 'socket.io-client';
import {
  Typography, Spin, Alert, message, Button, Select, DatePicker,
} from 'antd';

import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import fileDownload from 'js-file-download';

import Loader from '../../../components/Loader';
import { boldFormatter } from '../../../components/TableFormatter';
import PriceFormatter from '../../../components/PriceFormatter';
import Tag from '../../../components/Tag';

const { Text, Title } = Typography;
const { SearchBar } = Search;
const { MonthPicker, RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
const monthFormat = 'MM/YYYY';
const { ExportCSVButton } = CSVExport;
let interval = null;

@inject('store') class PaymentsList extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.state = {
      payments_list: [],
      isLoading: false,
      error: null,
      dateRange: 'No time range specified!',
      startDate: null,
      endDate: null,
      paymentStatusValue: null,
      account_type_filter: null,
      statementIsLoading: false,
    };
  }

  getPayments = () => {
    this.setState({ error: null, isLoading: true });

    const config = {
      headers: { authorization: sessionStorage.getItem('token') },
      params: {
        organisation_id: sessionStorage.getItem('organisation_id'),
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      },
    };

    axios.get(`${this.store.domain}/payments/${sessionStorage.getItem('organisation_id')}`, config)
      .then((response) => {
        const { data } = response.data;
        if (!data) {
          this.setState({ error: null, isLoading: false });
          return;
        }
        data.forEach((element, index) => {
          element.pos = index + 1;
          element.created_at = moment(element.created_at).format('lll');
          element.updated_at = moment(element.updated_at).format('lll');
          element.payment_completed_at = element.payment_completed_at && moment(element.payment_completed_at).format('lll') || 'N/A';
        });
        let filtered_payments = data;
        console.log({ filtered_payments });
        if (this.state.account_type_filter) filtered_payments = data.filter((value) => value.account_type === this.state.account_type_filter);
        if (this.state.paymentStatusValue) filtered_payments = filtered_payments.filter((value) => value.status === this.state.paymentStatusValue);
        this.store.updateValue('payments_list', filtered_payments);
        this.setState({ error: null, isLoading: false });
        this.store.postMonitoringData('payments dashboard', 'employees');
      })
      .catch((error) => {
        this.setState({ error: error.response && error.response.data.error, isLoading: false });
      });
  };

  componentDidMount() {
    this.getPayments();
    this.handleSocket();
  }

  componentWillUnmount() {
    this.socket.disconnect();
    if (interval !== null) {
      clearInterval(interval);
    }
  }

  handleSocket = () => {
    this.socket = io(this.props.store.domain, {
      transports: ['websocket'],
    });

    this.socket.on('connect', () => {
      const session_id = localStorage.getItem('payment_session_id');
      const organisation_id = localStorage.getItem('organisation_id');
      const that = this;
      if (session_id !== null && organisation_id !== null) {
        interval = setInterval(() => {
          message.info('Updating latest payments');
          that.socket.emit('session_id_request', JSON.stringify({ session_id, organisation_id }));
          that.socket.emit('status_request', JSON.stringify({ session_id, organisation_id }));
        }, 30000);
      }
    });

    this.socket.on('status_response', (data) => {
      if (data.length === 0) return;
      data.forEach((element, index) => {
        element.pos = index + 1;
        element.created_at = moment(element.created_at).format('LLL');
        element.updated_at = moment(element.updated_at).format('LLLL');
        element.payment_completed_at = element.payment_completed_at && moment(element.payment_completed_at).format('lll') || 'N/A';
      });

      this.store.updateValue('payments_list', data);
      // this.setState({ payments_list: data });
    });

    this.socket.on('connect_error', () => {
      console.log('connect error');
    });

    this.socket.on('session_id_response', (data) => {
      console.log(`received session data: ${data}`);
      if (!data) {
        console.log('Session empty. Removing localstorage item.');
        localStorage.removeItem('payment_session_id');
        clearInterval(interval);
        this.getPayments();
        message.info('Finished processing transactions. Getting all payments...', 10);
        this.socket.disconnect();
      }
    });

    this.socket.on('disconnect', () => {
      console.log('socket on disconnect');
    });
  }

  downloadFile = (data) => {
    const url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `pesalink_statement-${this.state.startDate}-${this.state.endDate}-.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    fileDownload(data, `pesalink_statement-${this.state.startDate}-${this.state.endDate}-.csv`);
  }

  handleCheckPesalnk = (e) => {
    console.log(`checked = ${e.target.checked}`);
    this.setState({ linkPesalnkData: e.target.checked });
  }

  onPaymentStatusSelect = (selected) => {
    this.setState({ paymentStatusValue: selected });
    if (selected === 'all') this.setState({ paymentStatusValue: null });
    this.getPayments();
  }

  onBankStatementDownload = (e) => {
    if (this.state.endDate === null || this.state.startDate === null) {
      message.error('Please specify a date range');
      return;
    }
    this.setState({ statementIsLoading: true });
    const config = {
      headers: { authorization: sessionStorage.getItem('token') },
      params: {
        organisation_id: sessionStorage.getItem('organisation_id'),
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      },
    };
    axios.get(`${this.store.domain}/organisations/${sessionStorage.getItem('organisation_id')}/accounts/statement`, config)
      .then((response) => {
        const csv_data = response.data || 'Unknown';
        message.info('Successfully fetched statement!');
        // this.setState({ balance: bal });
        this.downloadFile(csv_data);
      })
      .catch((error) => {
        // this.setState({ balance: 'Unknown' });
        message.error('Failed to fetch statement!');
      });
  }

  onDateRangeChange = (date, dateString) => {
    this.setState({
      dateRange: `Data from ${moment(date[0]).format('LL')} to ${moment(date[1]).format('LL')}`,
      startDate: dateString[0],
      endDate: dateString[1],
    });
  }

  onPaymentAccountTypeSelect = (selected) => {
    this.setState({ account_type_filter: selected });
    if (selected === 'none') this.setState({ account_type_filter: null });
    this.getPayments();
  }

  headerFormatter = (column) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {column.text}
      {/* { filterElement }
            { sortElement } */}
    </div>
  )

  priceFormatter(cell) {
    return (<PriceFormatter amount={cell} />);
  }

  accountDetailsFormatter(cell, row) {
    if (row.bank_name) {
      return (
        <div>
          <span style={{ color: 'black' }}>
            Bank: {row.bank_name}
          </span>
          <br />
          <span style={{ color: 'grey' }}>
            Account: {row.account_name}
          </span>
          <br />
          <span style={{ color: 'grey' }}>
            Account no: {row.account_no}
          </span>
        </div>
      );
    }
  }

  userStatus(cell) {
    return (
      <Tag status={cell} />
    );
  }

  tableNodata = (string) => (
    <div style={{
      paddingTop: '100px', paddingBottom: '100px', margin: 'auto', textAlign: 'center', backgroundColor: '#f8f8f8',
    }}
    >
      <Text>
        {' '}
        {`No ${string} found!`}
        {' '}
      </Text>
    </div>
  )

  MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div style={{ align: 'center' }}>
        <Button type="link" onClick={handleClick}>Download CSV</Button>
      </div>
    );
  };

  PList = observer(() => {
    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      sizePerPageList: [
        { text: '50', value: 50 },
        { text: '100', value: 100 },
        { text: 'All', value: 10000 },
      ],
    };

    const columns = [
      { dataField: 'pos', text: 'No' },
      {
        dataField: 'name', text: 'Name', ...boldFormatter,
      },
      {
        dataField: 'created_at', text: 'Date created',
      },
      // { dataField: 'employee_type', text: 'Employee type', filter: textFilter(),headerFormatter: this.headerFormatter },
      // { dataField: 'updated_at', text: 'Last updated', filter: textFilter(),headerFormatter: this.headerFormatter },
      { dataField: 'payment_name', text: 'Payment reason' },
      {
        dataField: 'method', text: 'Payment details', formatter: this.accountDetailsFormatter.bind(this),
      },
      // { dataField: 'total_sales', text: 'Total sales', filter: textFilter(),headerFormatter: this.headerFormatter },
      // { dataField: 'commission_percent', text: 'Commission percentage', filter: textFilter(),headerFormatter: this.headerFormatter },
      // { dataField: 'commission', text: 'Commission', filter: textFilter(), headerFormatter: this.headerFormatter },
      {
        dataField: 'amount', text: 'Paid amount', formatter: this.priceFormatter.bind(this),
      },
      {
        dataField: 'payment_completed_at', text: 'Time completed',
      },
      {
        dataField: 'status', text: 'Status', formatter: this.userStatus.bind(this),
      },
      {
        dataField: 'status_message', text: 'Status message',
      },
    ];
    // const rowStyle = { backgroundColor: '#c8e6c9', fontFamily: 'inherit' };

    return (
      <div className="grid_margin card">
        {this.state.payments_processing
          && (
            <Spin spinning={this.state.payments_processing}>
              <Alert
                message="Processing payments..."
                description="Please dont close or refresh this page"
                type="info"
              />
            </Spin>
          )

        }
        <div>
          <ToolkitProvider
            keyField="pos"
            data={this.store.payments_list}
            columns={columns}
            bootstrap4
            search
            exportCSV
          >
            {
    (props) => (
      <div style={{
        display: 'flex', width: '100%', flexDirection: 'column',
      }}
      >
        <SearchBar {...props.searchProps} />
        <BootstrapTable
          {...props.baseProps}
          rowEvents={this.rowEvents}
          noDataIndication={this.tableNodata(' Payments ')}
          pagination={paginationFactory(options)}
        />
        <this.MyExportCSV {...props.csvProps} />
      </div>
    )
  }
          </ToolkitProvider>
          {/* <BootstrapTable
            keyField="pos"
            bootstrap4
            noDataIndication={this.tableNodata(' Payments ')}
            data={this.store.payments_list}
            columns={columns}
            filter={filterFactory()}
            pagination={paginationFactory(options)}
            // selectRow={ selectRow }
            rowEvents={this.rowEvents}
          /> */}
        </div>
      </div>
    );
  })

  PListHeader = () => (
    <div style={{
      display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px', marginLeft: '20px', marginTop: '20px',
    }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Title level={3}>Payments sent</Title>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: '' }}>
          <p>{this.state.dateRange}</p>
        </div>
        <div style={{
          display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'space-between', width: '100%',
        }}
        >
          <RangePicker
            format={dateFormat}
            onChange={this.onDateRangeChange}
          />
          <div style={{ marginLeft: '0px', alignContent: 'center' }}>
            <Button type="link" onClick={this.getPayments}>Refresh.</Button>
          </div>
          <Select defaultValue="none" style={{ width: 300 }} onChange={this.onPaymentAccountTypeSelect}>
            <Option value="none">Employees and Expenses</Option>
            <Option value="employee">Employees only</Option>
            <Option value="business">Expenses only</Option>
          </Select>
          <Select defaultValue="all" style={{ width: 150 }} onChange={this.onPaymentStatusSelect}>
            <Option value="all">All Transactions</Option>
            <Option value="success">Success</Option>
            <Option value="failed">Failed</Option>
            <Option value="pending">Pending</Option>
          </Select>
          <Button
            type="primary"
            loading={this.state.statementlnkIsLoading}
            icon="download"
            onClick={this.onBankStatementDownload}
          >
          Download Pesalink Statement
          </Button>
        </div>
        {/* <br />
        <div>
          <Checkbox onChange={this.handleCheckPesalnk}>Link data from Pesalink</Checkbox>
          <Button
            type="primary"
            loading={this.state.pesalnkIsLoading}
            shape="round"
            icon="download"
            disabled={!this.state.dateRange}
            onClick={this.onPesaLnkDataDownload}
          >
          Download Excel
          </Button>
        </div> */}
      </div>
      <div />
    </div>

  )

  List = () => (
    <div>
      <this.PListHeader />
      {this.state.isLoading
        ? <Loader />
        : (
          <div className="overx">
            <Grid>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <this.PList />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        )
      }
    </div>
  )

  render() {
    return (
      <div>
        <this.List />
      </div>
    );
  }
}

export default PaymentsList;
